<template>
    <client-page>
        <v-container>
            <mypage-layout title="개인결제">
                <v-data-table v-bind="{ headers, items, loading }" dense hide-default-footer disable-filtering disable-pagination disable-sort class="v-data-table--default tbody-tr-cursor-pointer" no-data-text="개인결제 내역이 없습니다">
                    <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>

                    <template #[`item.actions`]="{ item }">
                        <template v-if="item.paymentAt">
                            <span> 결제완료 </span>
                        </template>
                        <template v-else>
                            <v-btn v-bind="{ ...btn_tertiary, loading }" block class="v-size--xx-small" @click="buyNow([item])">결제하기</v-btn>
                        </template>
                    </template>
                </v-data-table>

                <div class="pagination-wrap">
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </div>
            </mypage-layout>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_tertiary } from "@/assets/variables/attrs";
import { initShopShippingOption } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";

const headers = [
    {
        text: "개인결제번호",
        value: "code",
        width: 200,
    },
    {
        text: "개인결제명",
        value: "name",
    },
    {
        text: "결제금액",
        value: "price",
        formatter: (value) => `${value?.format?.() || value || 0}원`,
        width: 160,
        align: "end",
    },
    {
        text: "요청일자",
        value: "createdAt",
        formatter: (value) => value?.toDate?.() || value || "-",
        width: 120,
        align: "center",
    },
    {
        text: "결제일자",
        value: "paymentAt",
        formatter: (value) => value?.toDate?.() || value || "-",
        width: 120,
        align: "center",
    },
    {
        text: "",
        value: "actions",
        width: 120,
    },
].map((item) => ({ ...item, formatter: item?.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        ClientPage,
        PageSection,
        MypageLayout,
    },
    setup: () => ({
        headers,
        btn_tertiary,
    }),
    data: () => ({
        products: [],

        limit: 20,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.products].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query, withPopulate: true };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.selected = [];
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, products } = await api.v1.me.targetPayments.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.products = products;
            } finally {
                this.loading = false;
            }
        },

        async buyNow(items) {
            if (this.loading) return;
            else this.loading = true;

            try {
                var soldOut = items.find((item) => item.stock == 0);
                if (soldOut) throw new Error("결제할 수 없는 항목이 있습니다");

                let shippingOption = initShopShippingOption();
                const { carts } = await api.v1.me.carts.post(
                    {
                        carts: items.map((product) => ({
                            _product: product._id,
                            product,
                            name: "본품",
                            price: product.price,
                            salePrice: product.salePrice,
                            discountPrice: product.discountPrice,
                            stock: product.stock,
                            amount: 1,
                            isTargetPayment: true,
                        })),
                        shippingOption,
                    },
                    { headers: { mode: "buy" } }
                );

                const _carts = carts.map(({ _id }) => _id);
                this.$store.dispatch("pickup__target", _carts);
                this.$router.push({
                    path: "/shop/order",
                    query: { mode: "target" },
                });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
}
</style>
