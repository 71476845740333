<template>
    <client-page>
        <v-container>
            <mypage-layout title="1:1 문의 내역">
                <div v-if="accessToken">
                    <board-question-list v-if="mode == 'list'" v-bind="{ code }" />
                    <board-question-view v-else-if="mode == 'view'" v-bind="{ code }" />
                    <board-question-input v-else-if="mode == 'form'" v-bind="{ code }" />
                </div>
            </mypage-layout>
        </v-container>
    </client-page>
</template>

<script>
import { CENTER_QUESTION_CODES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import BoardQuestionList from "@/components/client/board/skin/board-question/list.vue";
import BoardQuestionInput from "@/components/client/board/skin/board-question/input.vue";
import BoardQuestionView from "@/components/client/board/skin/board-question/view.vue";

const code = CENTER_QUESTION_CODES.QUESTIONS.value;

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,

        BoardQuestionList,
        BoardQuestionInput,
        BoardQuestionView,
    },
    data: () => ({
        code,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        _question() {
            return this.$route.query._question;
        },
        mode() {
            if (this._question) return this.$route.query.mode || "view";
            else return this.$route.query.mode || "list";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!this.accessToken) {
                this.$router.push("/login");
            }
        },
    },
};
</script>
