var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('v-container', [_c('mypage-layout', {
    attrs: {
      "title": "1:1 문의 내역"
    }
  }, [_vm.accessToken ? _c('div', [_vm.mode == 'list' ? _c('board-question-list', _vm._b({}, 'board-question-list', {
    code: _vm.code
  }, false)) : _vm.mode == 'view' ? _c('board-question-view', _vm._b({}, 'board-question-view', {
    code: _vm.code
  }, false)) : _vm.mode == 'form' ? _c('board-question-input', _vm._b({}, 'board-question-input', {
    code: _vm.code
  }, false)) : _vm._e()], 1) : _vm._e()])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }