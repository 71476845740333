<template>
    <client-page>
        <v-container>
            <mypage-layout title="장바구니">
                <template v-if="carts.length">
                    <cart-head v-model="selected" v-bind="{ carts, carts__outOfStock }" v-on="{ removeSelected, removeDisabled }" />

                    <!-- S: cart-body -->
                    <div class="border-t border-2 border-dark">
                        <v-row no-gutters v-for="{ shipping, servicePrice, deliveryPrice, deliveryMessage, productGroups } in cartShippingGroups" :key="shipping?.code">
                            <v-col cols="12" xl="">
                                <template v-for="item in productGroups">
                                    <cart-item v-model="selected" v-bind="item" v-on="{ remove, search }" :key="item._id" />
                                </template>
                            </v-col>
                            <!-- S: PC -->
                            <v-col cols="12" xl="2" class="d-none d-xl-block">
                                <div class="d-flex align-center h-100 border-b pa-12px py-md-16px">
                                    <v-row class="row--xxs text-center">
                                        <v-col cols="12">
                                            <div class="txt txt--xs">총 배송비</div>
                                        </v-col>
                                        <v-col cols="12">
                                            <strong class="font-size-16 break-all">
                                                {{ (servicePrice + deliveryPrice)?.format?.() }}<small class="font-size-14 font-weight-regular">원</small>
                                                <v-tooltip bottom v-if="deliveryMessage">
                                                    <template #activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on" small color="grey lighten-3" class="ml-4px cursor-pointer">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span>{{ deliveryMessage }}</span>
                                                </v-tooltip>
                                            </strong>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <!-- E: PC -->
                        </v-row>
                    </div>
                    <!-- E: cart-body -->

                    <div class="pt-20px">
                        <cart-foot v-bind="{ selected }" v-on="{ order }" />
                    </div>

                    <div class="btn-wrap btn-wrap--lg">
                        <v-card rounded="" elevation="4">
                            <div class="pa-18px pa-md-24px">
                                <v-row align="center" justify="center" :class="$vuetify.breakpoint.lgAndUp ? 'row--lg' : ''">
                                    <v-col cols="auto">
                                        <div class="d-inline-flex align-center">
                                            <div class="txt txt--xs pr-10px pr-md-16px">총 주문금액</div>
                                            <strong class="d-flex align-center font-size-18 font-size-md-24 grey--text text--darken-4">
                                                <span>{{ totalPrice?.format?.() || 0 }}</span>
                                                <small class="font-size-14 font-weight-regular pl-2px">원</small>
                                            </strong>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="auto">
                                        <v-btn v-bind="{ ...btn_primary, ...$attrs }" x-large class="w-100 min-w-sm-200px" @click="order(carts)">전체 주문하기</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center">
                        <div class="mb-18px mb-md-24px">
                            <v-icon size="60px" color="primary">mdi-cart</v-icon>
                        </div>
                        <div class="tit tit--sm">장바구니에 담긴 상품이 없습니다.</div>
                        <div class="txt txt--sm mt-8px">원하시는 상품을 담아주세요.</div>

                        <div class="btn-wrap btn-wrap--lg">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </template>
            </mypage-layout>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { btn_primary } from "@/assets/variables";

import CartHead from "@/components/client/mypage/cart/cart-head.vue";
import CartItem from "@/components/client/mypage/cart/cart-item.vue";
import CartFoot from "@/components/client/mypage/cart/cart-foot.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";

export default {
    components: {
        CartHead,
        CartItem,
        CartFoot,
        ClientPage,
        MypageLayout,
    },
    props: {
        type: { type: String, default: "cart" },
    },
    data: () => ({
        btn_primary,

        carts: [],
        selected: [],

        loading: false,
    }),
    computed: {
        ...mapState(["shipping__everyAsset", "accessToken"]),
        ...mapGetters(["userLevel"]),
        cartShippingGroups() {
            return this.$getCartShippingGroups(this.carts).map((shippingGroup) => {
                const carts = shippingGroup.productGroups.flatMap(({ carts }) => carts);
                const servicePrice = this.$getServicePrice(carts);
                const deliveryPrice = this.$getDeliveryPrice(carts);
                return { ...shippingGroup, servicePrice, deliveryPrice };
            });
        },
        productPirce() {
            return this.$getProductPrice(this.carts);
        },
        servicePrice() {
            return this.$getServicePrice(this.carts);
        },
        discountPirce() {
            return this.$getDiscountPrice(this.carts);
        },
        levelDiscountPrice() {
            return this.$getLevelDiscountPrice(this.carts, this.userLevel);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts);
        },
        totalPrice() {
            return this.productPirce + this.deliveryPrice + this.servicePrice - this.discountPirce - this.levelDiscountPrice;
        },
        carts__outOfStock() {
            return this.cartShippingGroups.flatMap(({ productGroups }) => productGroups.flatMap(({ product, carts }) => (!product?.sells ? carts : [])));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        accessToken() {
            if (this.accessToken) this.init();
        },
    },
    methods: {
        ...mapMutations(["setCarts"]),
        ...mapActions(["getShipping__everyAsset"]),
        ...mapActions("enlipleTracker", ["callMtmOnCartPage"]),

        async init() {
            this.selected = [];
            this.getShipping__everyAsset();
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const _carts = (this.$store?.state?.carts || []).map(({ _id }) => _id);
                this.carts = (await api.v1.me.carts.gets({ params: { _carts } }))?.carts?.filter?.(({ product }) => product);
                this.setCarts({ carts: this.carts });
                this.enlipleTracker();
            } finally {
                this.loading = false;
                this.selected = [...this.cartShippingGroups.flatMap(({ productGroups }) => productGroups.flatMap(({ carts }) => carts))];
            }
        },

        order(selected = []) {
            try {
                if (!selected.length) throw new Error("구매하실 상품을 선택해주세요");

                const someShippingOutdated = selected.some(({ isShippingOutdated }) => isShippingOutdated);
                if (someShippingOutdated) throw new Error("만료된 배송정책이 있습니다.");

                const _carts = selected.map(({ _id }) => _id);
                this.$store.dispatch("pickup", _carts);

                this.$router.push("/shop/order");
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                await api.v1.me.carts.delete(item);
            } finally {
                this.loading = false;
                this.init();
            }
        },

        async removeSelected() {
            if (this.loading) return;
            else this.loading = true;
            try {
                for (const cart of this.selected) {
                    await api.v1.me.carts.delete(cart);
                }
            } finally {
                this.loading = false;
                this.init();
            }
        },

        async removeDisabled() {
            if (this.loading) return;
            else this.loading = true;

            try {
                for (const cart of this.carts__outOfStock) {
                    await api.v1.me.carts.delete(cart);
                }
            } finally {
                this.loading = false;
                this.init();
            }
        },

        enlipleTracker() {
            /** @type {import('@/store/enliple-tracker').EnlipleTrackerCallMtmProps & import('@/store/enliple-tracker').EnlipleTrackerCartPageProps} */
            let props = {
                convType: "etc",
                basket: {
                    product: this.carts?.map?.((item) => ({
                        productCode: item?.code || "",
                        productName: item?.name || "",
                        price: `${item?.salePrice || ""}`,
                        qty: `${item?.amount || ""}`,
                    })),
                    totalPrice: `${this.totalPrice || ""}`,
                    totalQty: `${this.carts?.reduce?.((sum, { amount }) => sum + amount, 0) || ""}`,
                },
            };
            this.callMtmOnCartPage(props);
        },
    },
};
</script>
