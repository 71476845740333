<template>
    <client-page>
        <v-container>
            <mypage-layout title="대시보드">
                <purchase-status hideOverall hideFilters />

                <!-- S: 찜한 상품 -->
                <page-section class="page-section--sm pb-0">
                    <template slot="containerImmersive">
                        <section-like-list active />
                    </template>
                </page-section>
                <!-- E: 찜한 상품 -->

                <!-- S: 작성 가능한 리뷰 -->
                <page-section class="page-section--sm pb-0">
                    <template slot="containerImmersive">
                        <section-reviewable-list />
                    </template>
                </page-section>
                <!-- E: 작성 가능한 리뷰 -->

                <!-- S: 공지사항 -->
                <page-section class="page-section--sm pb-0">
                    <template slot="containerImmersive">
                        <tit-wrap-line noDivider title="공지사항"></tit-wrap-line>
                        <router-view name="board" code="consumer-notice-board" skin="board-notice" />
                    </template>
                </page-section>
                <!-- E: 공지사항 -->
            </mypage-layout>
        </v-container>
    </client-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import PurchaseStatus from "@/components/client/mypage/purchase/purchase-status.vue";
import SectionLikeList from "@/components/client/mypage/dashboard/section-like-list.vue";
import SectionReviewableList from "@/components/client/mypage/dashboard/section-reviewable-list.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        TitWrapLine,
        PurchaseStatus,
        SectionLikeList,
        SectionReviewableList,
        NotificationList,
        NotificationView,
    },
    computed: {
        ...mapState(["user"]),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            this.getUser();
            this.replacePath();
        },
        replacePath() {
            let path = "/mypage/dashboard";
            if (!this.$route.path.includes(path)) this.$router.replace({ path });
        },
    },
};
</script>

<style></style>
